@import '../variables.scss';

.paper {
  background-color: $paper-background-color;
  padding: $spacing;
  color: $primary-color;
  margin-bottom: $spacing * 2;
}

.divider {
  background-color: $grid-color;
  margin-bottom: $spacing;
}

.header {
  color: $accent-color;
}

ul {
  padding-left: 30px;
}
