@import '../variables.scss';

.paper {
  background-color: $paper-background-color;
  padding: $spacing;
  color: $primary-color;
}

.divider {
  background-color: $grid-color;
}

.header {
  color: $accent-color;
}

a {
  text-decoration: none;
  color: $accent-color;
}
