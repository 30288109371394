@import '../variables.scss';

.root {
  height: $top-bar-height;
  background-color: $background-color;
  border-bottom: 1px solid #404040;
  text-align: center;
}

.color-primary {
  background-color: $background-color;
}

.corner {
  height: $top-bar-height;
  width: $navigation-width;
  // background-color: red;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.options {
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: $top-bar-height - 2 * 20px;
}

.option {
  display: inline-block;
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 20px;
  padding-right: 20px;
  line-height: $top-bar-height - 2 * 20px;
  text-align: center;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: 400;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 1.625rem;
  letter-spacing: 0.00735em;
  color: $primary-color;
  cursor: pointer;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */

  &:not(:last-child) {
    border-right: 2px solid #404040;
  }

  &:hover {
    color: $accent-color;
  }

  a {
    text-decoration: none;
    color: $primary-color;

    &:hover {
      color: $accent-color;
    }
  }

  .option-selected {
    color: $accent-color;

    a {
      color: $accent-color;
    }
  }
}

.option-selected {
  color: $accent-color;

  a {
    color: $accent-color;
  }
}
