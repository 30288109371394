$background-color: #333;

$top-bar-height: 80px;
$top-bar-z-index: 100;

$navigation-width: 220px;

$primary-color: #ddd;

$accent-color: #9c6;

$spacing: 10px;

$paper-background-color: #262626;

$grid-color: #404040;
