@import '../../variables.scss';

.downloads {
  display: inline-flex;
  padding: $spacing;
  background-color: $background-color;
  border: 1px solid $primary-color;
  margin-top: $spacing;
  margin-bottom: $spacing;

  .option {
    height: 100px;
    width: 100px;

    img {
      height: 100px;
      width: 100px;

      &:hover {
        background-color: darken($background-color, 2);
      }
    }
  }
}
